<template>
  <div class="container">
    <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-show="loading" size="24px">加载中...</van-loading>
    <van-icon
            name="bar-chart-o"
            color="#1989fa"
            size="10"
            style="position: absolute;right: 0; margin:5px;max-width: 60px;"
            v-if="isShowReport"
            @click="toreport"
            >统计报表</van-icon>
    <div class="qinfo" v-show="loading == false && hasQuestionnaire" :style="
      questionnaire.backgroundImageUrl &&
        questionnaire.backgroundImageUrl != ''
        ? 'background: url(' +
        questionnaire.backgroundImageUrl +
        '); background-size: contain;'
        : ''
    ">
      <!-- <div v-show="loading == false" > -->
      <div v-if="hasQuestionnaire">

        <h2 style="text-align: center">{{ questionnaire.title }}</h2>

        <div class="acontent" v-html="questionnaire.detailsHtml"></div>



        <div v-for="(item, index) in questionnaire.questionList" :key="item.id.toString()" class="questionList" :style="
          item.haserror === true
            ? 'border: 2px solid red;'
            : 'border: 2px solid rgba(255,255,255,0);'
        " :id="item.id.toString()">
          <div class="question">
            <h2>
              {{ index + 1 }}、{{ item.title }}
              {{
                item.typeId == "5" && item.answerText > 0
                ? "(" + item.answerText + "分)"
                : ""
              }}
            </h2>
          </div>

          <div class="acontent" v-html="item.detailsHtml"></div>

          <div v-if="item.typeId == 1" class="option">
            <van-radio-group v-model="item.checked" @change="checkquestion(index)">
              <van-radio :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()">{{ GetOptionTitle(option.title, oindex) }}
                <van-image v-if="
                  option.imageUrl != undefined && option.imageUrl.length > 10
                " :src="option.imageUrl">
                  <template v-slot:error>图片加载失败</template>
                </van-image>
              </van-radio>
            </van-radio-group>
            <div v-if="item.minLength ||
              item.minLength > 0" class="questionInput">
              其他
              <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                :error-message="item.errormessage" @blur="checkInput(index)" />
            </div>
          </div>
          <div v-if="item.typeId == 2" class="option">
            <van-checkbox-group v-model="item.checked" @change="checkquestion(index)">
              <van-checkbox shape="square" :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()">{{ GetOptionTitle(option.title, oindex) }}
                <van-image v-if="
                  option.imageUrl != undefined && option.imageUrl.length > 10
                " :src="option.imageUrl">
                  <template v-slot:error>图片加载失败</template>
                </van-image>
              </van-checkbox>
            </van-checkbox-group>
             <div v-if="item.minLength ||
              item.minLength > 0" class="questionInput">
              其他
              <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                :error-message="item.errormessage" @blur="checkInput(index)" />
            </div>
          </div>
          <!-- 文本 -->
          <div v-if="item.typeId == 3" class="questionInput">
            <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @input="checkInput(index)" />
          </div>
          <!-- 整数 -->
          <div v-if="item.typeId == 32" class="questionInput">
            <van-field size="large" type="digit" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @input="checkInput(index)" />
          </div>
          <!-- 数字 -->
          <div v-if="item.typeId == 33" class="questionInput">
            <van-field size="large" type="number" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @input="checkInput(index)" />
          </div>
          <!-- 手机号 -->
          <div v-if="item.typeId == 31" class="option">
            <van-field size="large" type="tel" maxlength="11" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @input="checkInputPhone(index)" />
          </div>
          <!-- 备注 -->
          <div v-if="item.typeId == 34" class="option">
            <div v-show="item.errormessage.length > 0" style="margin-left: 15px" class="van-field__error-message">
              {{ item.errormessage }}
            </div>

            <textarea class="texta" rows="2" style="height: 93px" autosize v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @input="checkInput(index)"></textarea>
          </div>
          <!-- 日期 -->
          <div v-if="item.typeId == 4" class="option">
            <van-cell color="#646566" title="日期" :value="item.answerText" @click="item.show = true" />
            <van-calendar v-model="item.show" @confirm="onConfirm($event, index)" />
          </div>
          <!-- 评分 -->
          <div v-if="item.typeId == 5" class="option">
            <van-rate v-model="item.answerText" @change="onChange($event, index)" count="10" color="#ffd21e"
              void-icon="star" void-color="#eee" size="25px" />
          </div>
          <!--上传文件-->
          <div v-if="item.typeId == 6">
            <van-field label="上传图片">
              <template #input>
                <van-uploader v-model="item.filelist" multiple :name="index" :max-count="3" :after-read="afterReadFile" />
              </template>
            </van-field>
          </div>
        </div>
        <div v-if="questionnaire.summary != undefined" style="margin: 0 10px 20px 20px">
          <h3>{{ questionnaire.summary }}</h3>
        </div>

        <!-- 提交 -->
        <div class="submit">
          <van-button v-if="questionnaire.showType == 1 || questionnaire.showType == 500" round block
            :disabled="submitDisabled" type="primary" native-type="submit" @click="onsubmit()">
            提交
          </van-button>

          <div class="submitSuccess" id="posterImage" v-show="questionnaire.showType == 1" @click="toHome">
            {{ showMessage }}
          </div>

          <div class="submitError" v-show="questionnaire.showType > 1" @click="toHome">
            {{ showMessage }}
          </div>
        </div>

        <div v-if="poster.length > 0">
          <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            长按图片，转发给朋友
          </van-notice-bar>
          <!-- 轮播图 -->
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(image, index) in poster" :key="index">
              <img v-lazy="image.qrPrviewImage" />
            </van-swipe-item>
          </van-swipe>
        </div>

        
        <p v-if="isShowReport" style="text-align: center">部门负责人请于右上角查看本部门汇总统计</p>
      </div>
    </div>
    <div v-if="loading == false && !hasQuestionnaire" style="padding-top: 15px">
      <!-- <div style="color: white; text-align: center">
          {{ showTypeInfo }}
        </div> -->
      <div class="submitError" @click="toHome">
        {{ showMessage }}
      </div>
      <div v-if="poster.length > 0">
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
          长按图片，转发给朋友
        </van-notice-bar>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item v-for="(image, index) in poster" :key="index">
            <img v-lazy="image.qrPrviewImage" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
</div>
</template>

<script>
import { Toast } from "vant";
import {

  getQuestionnaire,
  AddAnswer,
  getPoster,
} from "@/api/question";

import {
  getUserInfo
} from "@/api/user"
import { uploadfileplus } from "@/api/file";
import store from "@/store";

export default {
  data() {
    return {
      show: false,
      loading: true,
      questionnaire: {},
      hasQuestionnaire: true,
      phoneerror: "",
      usernameerror: "",
      submitDisabled: false,
      showMessage: "",
      showTypeInfo: "",
      date: "",
      answer: {
        questionnaireId: "",
        parentDeviceId: "00000000-0000-0000-0000-000000000000",
        sourceType: 1,
        deviceId: "",
        userCode: "",
        userName: "",
        phone: "",
        position: "",
        province: "",
        city: "",
        county: "",
        address: "",
        questionList: [],
      },
      poster: [],
    };
  },

  created() {
    this.initData();
  },
  computed: {
    
    isShowReport() {
      return (
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0&&this.$route.query.q==='ab0f2a75-343a-4be3-a094-5c9471a68abf'
      );
    },
  },
  methods: {
    /***题型：
'单选题': 1 
'多选题': 2 
'文本': 3 
'日期': 4 
'评分': 5 
'手机号': 31 
'整数': 32 
'数字': 33 
'文本备注': 34 
 */
    async initData() {
      let questionnaireId = this.$route.query.q;

      //let userId = ""; //this.$route.query.u;
      this.submitDisabled = false;
      var userinfo = await getUserInfo();
      let deviceId = userinfo.data.data.id;
      //   let deviceId = localStorage.getItem("deviceId");
      //   if (deviceId == undefined || deviceId == "" || deviceId == null) {
      //     var dresult = await getDevice(questionnaireId, userId);
      //     localStorage.setItem("deviceId", dresult.data.data);
      //     deviceId = dresult.data.data;
      //   }

      this.answer.deviceId = deviceId;
      this.answer.questionnaireId = questionnaireId;

      //this.answer.userId = userId;

      let parentDeviceId = this.$route.query.d;
      if (
        parentDeviceId == undefined ||
        parentDeviceId == "" ||
        parentDeviceId == null
      ) {
        this.answer.parentDeviceId = "00000000-0000-0000-0000-000000000000";
      } else {
        this.answer.parentDeviceId = parentDeviceId;
      }

      var quResult = await getQuestionnaire(
        questionnaireId,
        deviceId
      );
      console.log(quResult);
      if (quResult.data.success) {
        var myquestionnaire = quResult.data.data;
        for (
          let index = 0;
          index < myquestionnaire.questionList.length;
          index++
        ) {
          //初始化附加属性值
          myquestionnaire.questionList[index].haserror = false;
          myquestionnaire.questionList[index].errormessage = "";
          myquestionnaire.questionList[index].filelist = [];
          if (myquestionnaire.questionList[index].typeId == 5) {
            myquestionnaire.questionList[index].answerText = 0;
          } else {
            myquestionnaire.questionList[index].answerText = "";
          }

          myquestionnaire.questionList[index].show = false;
        }
        this.questionnaire = myquestionnaire;
      } else {
        this.hasQuestionnaire = false;
        this.showMessage = quResult.data.errorMessage;
        Toast(quResult.data.errorMessage);
      }
      if (this.questionnaire.showType > 1) {
        this.submitDisabled = true;
        this.hasQuestionnaire = false;
      }
      if (this.questionnaire.showType == 2) {
        this.showMessage = this.questionnaire.title + "调研未开始，敬请期待！,点击返回KM首页";
      }
      if (this.questionnaire.showType == 3) {
        this.showMessage =
          this.questionnaire.title + "调研已结束，期待您下次参与! 点击返回KM首页";
      }
      if (this.questionnaire.showType == 4) {
        this.showMessage = this.questionnaire.successMessage?this.questionnaire.successMessage:"您已完成本次调研，感谢您的帮助与支持! 点击返回KM首页";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }

      this.loading = false;
    },

    afterReadFile: async function (files, detail) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfileplus(formData, "QA");

      let url = aresult.data; // Get url from response
      let index = detail.name;
      this.questionnaire.questionList[index].filelist =
        this.questionnaire.questionList[index].filelist.filter(function (item) {
          return item.url != undefined && item.url.indexOf("http") >= 0
        });
      this.questionnaire.questionList[index].filelist.push({
        url: url,
        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },
    checkquestion(index) {
      this.questionnaire.questionList[index].haserror = false;
    },
    checkInput(index) {
      this.questionnaire.questionList[index].haserror = false;
    },
    checkInputPhone(index) {
      if (this.questionnaire.questionList[index].answerText.length < 11) {
        this.questionnaire.questionList[index].errormessage = "";
        return;
      }
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.questionnaire.questionList[index].answerText)) {
        this.questionnaire.questionList[index].errormessage =
          "请填写有效的手机号码";
        this.questionnaire.questionList[index].haserror = true;
      } else {
        this.questionnaire.questionList[index].errormessage = "";
        this.questionnaire.questionList[index].haserror = false;
      }
    },
    toHome() {
      window.location.replace(window.location.origin);
    },
    async onsubmit() {
      this.submitDisabled = true;
      var checked = true;
      var itemerror = "";
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      for (
        let index = 0;
        index < this.questionnaire.questionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.questionnaire.questionList[index];
        if (element.typeId == 1
          && element.isMust
          && element.checked == undefined
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          element.typeId == 2 && element.isMust
          && (element.checked == undefined || element.checked.length == 0)
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          (element.typeId == 4 ||
            element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust &&
          element.answerText == ""
        ) {
          element.haserror = true;
        } else if (element.typeId == 5 && element.answerText == 0) {
          element.haserror = true;
        }

        if (element.typeId == 31 && element.isMust) {
          if (!phonereg.test(element.answerText)) {
            element.errormessage = "请填写有效的手机号码";
            element.haserror = true;
          } else {
            element.haserror = false;
            element.errormessage = "";
          }
        }

        // str.replace(/(^\s*)|(\s*$)/g, "")
        if (
          (element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust
        ) {
          if (
            (element.minLength || element.minLength > 0) &&
            element.answerText.replace(/(^\s*)|(\s*$)/g, "").length <
            element.minLength
          ) {
            element.errormessage = "最小长度是" + element.minLength + "个字符";
            element.haserror = true;
          } else {
            element.haserror = false;
            element.errormessage = "";
          }
        }

        //第一个错误,页面会滚动到
        if (checked && element.haserror) {
          itemerror =
            index +
            1 +
            "、" +
            element.title +
            (element.typeId <= 2 ? " 请选择" : " 请填写");
          checked = false;
          Toast(itemerror);
          document.getElementById(element.id).scrollIntoView();
        }
      }

      if (!checked) {
        this.submitDisabled = false;
        return;
      }
      //answerItem =答案
      this.answer.questionList = [];
      for (
        let index = 0;
        index < this.questionnaire.questionList.length;
        index++
      ) {
        const question = this.questionnaire.questionList[index];
        var answerItem = {};
        if (question.typeId == 1) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked.toString(),
            answerText: question.answerText.toString(),
          };
        }
        else if (question.typeId == 6) {

          let urls = "";
          question.filelist.forEach(element => {
            if (element.url != undefined) {
              urls += element.url + " "
            }
          });
          urls = urls.trimEnd();
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: urls,
          };
        }
        else if (question.typeId == 2) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked.toString(),
            answerText: question.answerText.toString(),
          };
        } else if (question.typeId >= 3 && question.typeId <= 50) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: question.answerText.toString(),
          };
        }

        this.answer.questionList.push(answerItem);
      }
      var amapInfo = localStorage.getItem("AMapInfo");
      console.log(amapInfo);
      if (amapInfo == "SUCCESS") {
        this.answer.province = localStorage.getItem("AMapInfo_province");
        this.answer.city = localStorage.getItem("AMapInfo_city");
        this.answer.county = localStorage.getItem("AMapInfo_district");
        this.answer.address = localStorage.getItem("AMapInfo_address");
        this.answer.position =
          localStorage.getItem("AMapInfo_lng") +
          "," +
          localStorage.getItem("AMapInfo_lat");
        if (this.answer.city == "") {
          this.answer.city = this.answer.province;
        }
      }

      try {
        var areulst = await AddAnswer(this.answer);
        if (areulst.data.success) {
          if (
            areulst.data.scoreMessage !== undefined &&
            areulst.data.scoreMessage != ""
          ) {
            Toast({
              message: areulst.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          } else {
            Toast("调研提交成功");
          }

          this.showMessage =
           this.questionnaire.successMessage?this.questionnaire.successMessage: "提交成功，您已完成本次调研，感谢您的帮助与支持。点击返回KM首页";
        } else {
          Toast(areulst.data.errorMessage);
          this.submitDisabled = false; //questionnaire.showType
          this.questionnaire.showType = 500;
          this.showMessage = "调研提交失败";
          if (areulst.data.errorCode == "PhoneError") {
            this.showMessage = areulst.data.errorMessage;
          }
        }
      } catch (error) {
        this.submitDisabled = false;
      }
    },

    async getMyPoster() {
      var presult = await getPoster(
        this.answer.questionnaireId,
        this.answer.userCode,
        this.answer.deviceId
      );
      if (presult.data.success) {
        this.poster = presult.data.data;
        if (this.hasQuestionnaire && this.poster.length > 0) {
          setTimeout(() => {
            document.getElementById("posterImage").scrollIntoView();
          }, 500);
        }
      }
    },

    inputUserName() {
      if (this.answer.userName.length < 1) {
        return;
      }
      if (this.answer.userName == "") {
        this.usernameerror = "请填写姓名";
      } else {
        this.usernameerror = "";
      }
    },

    GetOptionTitle(title, index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index] + "、" + title;
    },
    onChange(value, index) {
      Toast("评分：" + value + "分");
      this.questionnaire.questionList[index].haserror = false;
      this.questionnaire.questionList[index].answerText = value;
    },
    formatDate(date) {
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${date.getFullYear()}-${month}-${day}`;
    },
    onConfirm(date, index) {
      this.questionnaire.questionList[index].haserror = false;
      this.questionnaire.questionList[index].answerText = this.formatDate(date);
      this.questionnaire.questionList[index].show = false;
    },
    toreport(){
      window.location.href = "https://ynscrm.bjznpz.com/home/login?returnUrl=https%3A%2F%2Fynscrm.bjznpz.com%2Fhome%2Freportpage%2F730&token=" + store.state.user.token;
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  //padding: 0px 10px 20px 10px;
}

.qinfo {
  padding-bottom: 50px;
}

.questionList {
  margin: 0px 10px 20px 10px;
}

.questionInput {
  // height: 75px;
  margin: 0px 5px 5px 5px;
  font-size: 16px;
}

.question {
  margin: 20px 10px 20px 10px;
}

.van-checkbox {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-checkbox[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.van-radio {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-radio[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.submit {
  padding: 30px 20px 10px;
}

.submitInfo {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitSuccess {
  color: green;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitError {
  color: red;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.posterInfo {
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
}

.acontent {
  /deep/ p {
    margin: 2px 10px;
  }

  /deep/ div {
    margin: 2px 10px;
  }

  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }

  /deep/ video {
    max-width: 100% !important;
  }
}

.my-swipe .van-swipe-item {
  //height: 150px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  //background-color: #39a9ed;
}

.my-swipe img {
  display: inline-block;
  width: 75%;
  height: 10%;
}

.texta {
  background: 0%;
  width: 100%;
  height: 63px;
  margin-left: 5px;
  border: 1px solid #d8d8d8;
}

.van-cell {
  border: 1px solid #d8d8d8;
  background: 0%;
  margin-left: 5px;
  color: #646566;
}
</style>